.contact-social-medial {
  margin-left: -30px;
}

.contact-social-medial ul {
  /* align-items: left;
  text-align: left;
  justify-content: left; */
  display: flex;
}
.contact-social-medial ul li {
  margin: 0 10px 0 0;
}
