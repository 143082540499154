.header {
  position: fixed;
  width: 100%;
  top: 0;
  overflow: hidden;
  background-color: var(--black);
  height: 80px;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
  width: 100%;
}

.header .navbar .navbar-logo {
  margin-left: 95px;
}

.header .navbar .navbar-logo .logo {
  flex: 1;
  width: 40px;
  height: 40px;
}

.header .navbar img {
  width: 250px;
  height: auto;
}

.header .nav-menu {
  display: flex;
  margin-right: 95px;
}

.header .nav-menu .nav-item {
  padding: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.header .nav-menu .nav-item a {
  cursor: pointer;
  text-decoration: none;
}

.header .nav-menu .nav-item .active {
  color: var(--orange);
  padding-bottom: 12px;
  border-bottom: 3px solid var(--orange);
}

.header .nav-menu .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--orange);
  /* color: var(--orange); */
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  .header .navbar ul {
    padding: 0;
  }
  .header .nav-menu {
    margin-right: 0px;
  }
  .header .navbar .navbar-logo {
    margin-left: 0;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 80px;
    flex-direction: column;
    background-color: #242222;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .header .nav-menu .nav-item {
    margin: 1.2rem 0;
  }

  .header .nav-menu .nav-item {
    padding: 0;
  }

  .header .nav-menu .nav-item a:hover {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
    background-color: white;
    color: #242424;
    padding-bottom: 12px;
    border-bottom: 3px solid var(--orange);
  }

  .header .navbar img {
    width: 150px;
  }
}
