.social-medial {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.social-medial ul li {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 991px) {
  .social-medial ul {
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    list-style: none;
  }
  .social-medial ul li {
    margin: 0 10px 0 0;
  }
}
