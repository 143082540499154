@import "./styles/_variables.scss";

.global-main-header {
  margin: 80px;
}
.global-header {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.global-title {
  font-weight: bold;
}
.global-sub-title {
  font-size: 16px;
  color: #454545;
  margin-bottom: 15px;
}
.global-line-center {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.global-line {
  width: 130px;
  border-bottom: 2px solid #000;
  line-height: 0em;
  margin: auto;
}

.global-line span {
  background: #fd6f41;
  padding: 0 20px;
  border-radius: 20px;
  font-size: 8px;
}
:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.7);
  --black: #101e21;

  --colo1: #a0bcca;
  --colo2: #a0bc2a;
  --red: #f03c02;
  --orange: #fd6f41;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 910px) {
}
