.footer-main {
  background: rgb(32, 32, 38);
  color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px 0;
}
