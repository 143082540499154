.services-main {
  margin-top: 80px;
}
.services-content {
  margin-top: 30px;
}

.services-card-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.services-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 50%;
  margin: 10px;
  border-radius: 10px;
  background: #202a44;
  height: fit-content;
}
.services-card-title {
  align-items: center;
  text-align: center;
  justify-content: center;
  background: lavender;
  border-radius: 0 0 20px 20px;
  padding: 15px;
  color: black;
  font-weight: bold;
}

.services-card-content {
  margin-top: 20px;
  color: white;
  padding: 20px 35px;
}

.services-card-content ul {
  list-style: none;
  list-style-type: "✓ ";
}

.services-card-content ul li {
  margin-bottom: 10px;
  font-size: 15px;
}

@media screen and (max-width: 991px) {
  .services-card-row {
    display: block;
  }
  .services-card {
    width: auto;
    margin: 50px;
  }
}

@media screen and (max-width: 767px) {
  .services-card {
    width: auto;
    margin: 50px 0;
  }
}

@media screen and (max-width: 575px) {
  .services-card {
    width: auto;
    margin: 50px 10px;
  }
}
