/*==============================
  Grays
==============================*/
$gray-100: #f9fafc;
$gray-200: #f8fafd;
$gray-300: #e7eaf3;
$gray-400: #bdc5d1;
$gray-500: #97a4af;
$gray-600: #8c98a4;
$gray-700: #677788;
$gray-800: #71869d;
$gray-900: #1e2022;

/*==============================
  Colors
==============================*/
$white: #fff;
$dark: #132144;
$blue: #377dff;
$teal: #00c9a7;
$cyan: #09a5be;
$yellow: #f5ca99;
$red: #ed4c78;

/*==============================
  Theme Colors
==============================*/
$primary: #f340b2;
$secondary: $gray-800;
$success: $teal;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$dark: $dark;

$header-backgound: #063146;
$white: #ffffff;
$green: #4db848;
$blue: #608aba;
$turquoise: #2e8879;
$cyan: #9ff4e6;
$copy: #313131;
$orange: #ff6600;

$light-grey: #c5c5c5;
$grey: #808285;
$dark-grey: #313131;
$bright-gray: rgb(97, 88, 88);
$danger: #ed4c78;
$input-border-width: 0.0625rem !default;
$input-border-color: rgba($gray-300, 0.7) !default;

/*==============================
  Borders
==============================*/
$border-width: 0.0625rem !default;
$border-color: rgba($gray-300, 0.7) !default;

/*==============================
  Border Radius
==============================*/
$border-radius: 0.5rem !default;
$border-radius-sm: 0.3125rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-pill: 50rem !default;
$border-radius-rounded: 50% !default;

/*==============================
  Allows for customizing button radius independently from global border radius
==============================*/
$input-border-radius: $border-radius-sm !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-sm !default;
$form-select-border-radius: $input-border-radius !default;
$input-group-text-border-width: 0 !default;

/*==============================
  Z-index
==============================*/
$input-group-merge-addons-z-index: 6 !default;

/*==============================
  Font Weights
==============================*/
$fw-reg: 400;
$fw-bold: 700;

/*==============================
  Breakpoints
==============================*/
$breakpoints: (
  lg: 1080px,
  md: 840px,
  sm: 580px,
);
