.projects-main {
  margin: 80px 0;
}
.projects-section {
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}
.tag {
  margin-left: 10px;
  background: gray;
}
.tag-active {
  background: rgb(56, 53, 53);
}
.project-lists {
  margin-top: 20px;
}

.project-card {
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 70%; */
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
}
.project-card-picture {
  padding-bottom: 20px;
}
.project-card-picture img:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}
.project-card-img {
  width: 100%;
}
.project-mobile-card-img {
  width: auto;
  height: 350px;
}
.project-card-title {
  text-align: left;
}
.project-card-link {
  text-align: left;
}
.project-card-link-row {
  display: flex;
  flex-direction: row;
}
.project-card-button-left {
  flex: 1;
}
.project-card-button-right {
  display: block;
}
.project-modal {
  margin: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.project-images {
  margin-bottom: 20px;
}
.project-images img {
  width: 100%;
  height: 350px;
}

.project-images-mobile {
  margin-bottom: 20px;
}
.project-images-mobile img {
  width: auto;
  height: 350px;
}

.project-images img:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

.project-images-mobile img:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  outline: black;
  border-radius: 50%;
  border: 1px solid black;
  background-color: black;
}

.flex {
  display: flex;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}
.align-items-center {
  display: flex;
  align-items: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.side-menu-modal-logo {
  cursor: pointer;
}

.side-menu-modal-logo .app-stores-logo {
  width: auto;
  height: 50px;
}

.side-menu-modal-logo .play-stores-logo {
  width: auto;
  height: 77px;
}

.side-menu-modal-tabs {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15;
}

.side-menu-modal-tab-item {
  flex: 1;
  border: none;
  justify-content: center;
  align-items: center;
  color: #111;
  background: #eee;
  font-size: 18px;
  font-weight: 500;
  max-width: 140px;
  width: 100%;
  padding: 7px;
  margin: 1rem 1rem;
  border-radius: 40px;
}

.side-menu-modal-tab-item-unique .active {
  background: var(--orange);
  color: white;
}
.mobile-qr-code img {
  width: 250px;
  height: 250px;
}

@media screen and (max-width: 991px) {
  .project-images img {
    height: auto;
  }

  .project-images-mobile img {
    height: 250px;
  }

  .project-card-img {
    height: auto;
  }

  .project-mobile-card-img {
    height: 250px;
  }

  .side-menu-modal-tab-item {
    font-size: 15px;
    max-width: 100px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
}
