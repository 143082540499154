.about-main {
  margin-top: 20px;
}

.about-section {
  margin-top: 50px;
}
.about-card-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.about-card {
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 50%;
  margin: 10px;
  border-radius: 10px;
}
.about-icon {
  margin: 10px 20px;
  font-size: 25px;
}
.about-card-subject {
  font-weight: bold;
}
.about-card-task {
  font-size: 14px;
  padding-bottom: 10px;
  color: #454545;
}
.about-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.about-description {
  margin: 25px 0 25px 0;
  text-align: justify;
}
.about-section-image {
  width: 70%;
  border-radius: 50px;
}

.about-view-content {
  color: blue;
  cursor: pointer;
}

.about-section-image:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

@media screen and (max-width: 991px) {
  .about-main {
    margin-top: 80px;
  }

  .about-section {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .about-description {
    margin: 20px 10px 30px 10px;
  }
  .about-section-image {
    border-radius: 50px;
    margin-bottom: 30px;
  }
}
