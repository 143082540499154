.home-main {
  margin: 80px 0;
}
.home-container {
  display: flex;
  height: 10%;
  min-height: fit-content;
  background-color: #202a44;
}

.home-skills {
  font-style: italic;
  color: #ff6600;
}
.home-parent {
  display: flex;
  flex-direction: row;
  color: aliceblue;
  padding-top: 100px;
  margin-bottom: 10px;
}
.home-content {
  margin: 0px 20px;
}
.home-details {
  display: flex;
  flex-direction: row;
}
.profile-name {
  font-size: 24px;
  font-family: Poppins SemiBold;
}

.home-role {
  margin: 14px 0 24px 0;
}
.home-role h2 {
  color: antiquewhite;
  font-weight: 500;
}
.home-discription {
  margin: 5px 0 0 0;
}
.home-social-medial {
  margin: 50px 90px 0 0;
}
.home-button {
  margin-top: 40px;
}
.home-button-greeting {
  margin-right: 40px;
}
.home-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.home-picture-layout {
  box-shadow: 0 5px 0px 0px white;
  border-radius: 50%;
  height: 380px;
  width: 380px;
}
.home-picture-background {
  height: 380px;
  width: 380px;
  background-image: url("../../../public/images/cyrille2.png");
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.home-picture-background:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

@media screen and (max-width: 991px) {
  .home-main-header {
    margin-bottom: -50px;
  }
  .home-container {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .home-parent {
    flex-direction: column-reverse;
    padding-top: 20px;
    margin-bottom: 40px;
  }
  .home-social-medial {
    margin: 50px 0 0 0;
  }
  .home-picture-layout {
    height: 320px;
    width: 320px;
  }
  .home-picture-background {
    height: 320px;
    width: 320px;
  }
  .home-details {
    display: block;
    flex-direction: initial;
  }
}

@media screen and (max-width: 767px) {
  .home-container {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .home-parent {
    flex-direction: column-reverse;
    padding-top: 20px;
    margin-bottom: 40px;
  }
  .home-social-medial {
    margin: 50px 0 0 0;
  }
  .home-picture-layout {
    height: 320px;
    width: 320px;
  }
  .home-picture-background {
    height: 320px;
    width: 320px;
  }
  .home-details {
    display: block;
    flex-direction: initial;
  }
}

@media screen and (max-width: 575px) {
  .home-button-greeting {
    font-size: 9px;
  }
  .home-button-resume {
    font-size: 9px;
  }
}
