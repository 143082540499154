@import "../../styles/variables";

.btn {
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 10px;
  text-decoration: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.btn a {
  display: inline-block;
  border-radius: 25px;
  color: #ffffff;
  padding: 10px 25px;
  text-decoration: none;
  border: none;
  font-size: 15px;
}

.btn:disabled {
  background-color: $light-grey;
  color: $dark-grey;
  cursor: default;
}
.btn:hover {
  color: #ffffff;
  background: rgb(56, 53, 53);
}
.primary {
  background: #1e90ff;
}
.secondary {
  background: #696969;
}
.success {
  background: $green;
}
.orange {
  background: $orange;
}
.danger {
  background: darkred;
}
.info {
  background: #00ced1;
}
.warning {
  background: goldenrod;
}
.dark {
  background: rgb(56, 53, 53);
}
.none {
  background: none;
}
.gray {
  background: gray;
}

.small {
  font-size: 0.875rem;
}
.xSmall {
  font-size: 0.675rem;
}
