@import "../../../src/styles/_variables.scss";

.resume-main {
  margin-top: 80px;
}

.resume-section {
  margin-top: 30px;
}

.resume-details {
  flex: content;
}
.resume-list-down ul {
  width: 80%;
}

.resume-list-down li:hover {
  background-color: rgba(0, 0, 0, 0.821);
  color: white;
  border-radius: 0 20px 20px 0;
}
.resume-list ul li {
  padding: 10px 100px 10px 0;
  cursor: pointer;
}
.resume-list-icon {
  background-color: black;
  color: white;
  padding: 25px 15px 80px 15px;
  margin-right: 20px;
  font-size: 25px;
}
.resume-list-active {
  background-color: black;
  color: white;
  border-radius: 0 20px 20px 0;
}

.resume-education-row {
  display: flex;
  flex-direction: row;
}
.resume-education-detail {
  margin-right: 50px;
  flex: 1;
}
.resume-education-detail .resume-education-detail-title {
  color: rgb(255, 119, 0);
  font-weight: bold;
}
.resume-education-detail span {
  font-size: 25px;
  color: $orange;
}

.resume-education-year {
  margin-top: 10px;
}
.resume-education-year span {
  color: white;
  background: $orange;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}
.resume-education-title {
  margin: -15px 0 0 27px;
  text-align: justify;
}
.resume-education-title ul {
  list-style: none;
  list-style-type: circle;
}
.resume-technical-skills ul {
  list-style: none;
  list-style-type: disc;
}

.resume-technical-skills li {
  margin-top: 8px;
}

.bar {
  height: 20px;
  position: relative;
  font-size: 14px;
  text-align: center;
  color: white;
}

.positive {
  background-color: green;
  height: 100%;
  position: absolute;
  left: 0;
}

.negative {
  background-color: $orange;
  height: 100%;
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .resume-list-down ul {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .resume-list {
    margin: 0 0 80px 0;
  }

  .resume-education-row {
    margin-left: 25px;
  }
}
