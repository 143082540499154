.contact-main {
  margin-top: 130px;
  margin-bottom: 150px;
}
.contact-section {
  background: rgb(32, 32, 38);
  margin-top: 30px;
  border-radius: 20px;
}
.contact-row {
  padding: 30px;
  color: white;
}
.contact-details {
  flex: 1;
}
.contact-in-touch {
  font-weight: bold;
  margin-bottom: 20px;
}
.contact-email {
  padding-right: 10px;
}
.contact-vl {
  border-left: 5px solid white;
  padding-left: 20px;
}
.contact-image {
  margin-top: 100px;
}
.contact-image p {
  color: rgb(208, 197, 197);
}
.contact-img {
  width: 100%;
  border-radius: 20px;
}

.contact-list {
  margin-top: 100px;
  padding: 20px;
  background: white;
  border-radius: 20px;
}
.contact-label {
  color: rgb(32, 32, 38);
}
.contact-list-each {
  margin-bottom: 25px;
}
.contact-button {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .contact-main {
    margin-top: 80px;
  }
  .contact-list {
    margin-top: 30px;
  }
  .contact-image {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
}
