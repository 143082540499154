.backToTop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 50px;
  width: 50px;
  font-size: 30px;
  background: #ff6600;
  border: none;
  border-radius: 10px;
  color: white;
}
